export const countriesarray = [
  { code: "IN", countryname: "India" },
  { code: "AF", countryname: "Afghanistan" },
  { code: "AX", countryname: "\u00c5land Islands" },
  { code: "AL", countryname: "Albania" },
  { code: "DZ", countryname: "Algeria" },
  { code: "AS", countryname: "American Samoa" },
  { code: "AD", countryname: "Andorra" },
  { code: "AO", countryname: "Angola" },
  { code: "AI", countryname: "Anguilla" },
  { code: "AQ", countryname: "Antarctica" },
  { code: "AG", countryname: "Antigua and Barbuda" },
  { code: "AR", countryname: "Argentina" },
  { code: "AM", countryname: "Armenia" },
  { code: "AW", countryname: "Aruba" },
  { code: "AU", countryname: "Australia" },
  { code: "AT", countryname: "Austria" },
  { code: "AZ", countryname: "Azerbaijan" },
  { code: "BS", countryname: "Bahamas" },
  { code: "BH", countryname: "Bahrain" },
  { code: "BD", countryname: "Bangladesh" },
  { code: "BB", countryname: "Barbados" },
  { code: "BY", countryname: "Belarus" },
  { code: "BE", countryname: "Belgium" },
  { code: "BZ", countryname: "Belize" },
  { code: "BJ", countryname: "Benin" },
  { code: "BM", countryname: "Bermuda" },
  { code: "BT", countryname: "Bhutan" },
  { code: "BO", countryname: "Bolivia, Plurinational State of" },
  { code: "BQ", countryname: "Bonaire, Sint Eustatius and Saba" },
  { code: "BA", countryname: "Bosnia and Herzegovina" },
  { code: "BW", countryname: "Botswana" },
  { code: "BV", countryname: "Bouvet Island" },
  { code: "BR", countryname: "Brazil" },
  { code: "IO", countryname: "British Indian Ocean Territory" },
  { code: "BN", countryname: "Brunei Darussalam" },
  { code: "BG", countryname: "Bulgaria" },
  { code: "BF", countryname: "Burkina Faso" },
  { code: "BI", countryname: "Burundi" },
  { code: "KH", countryname: "Cambodia" },
  { code: "CM", countryname: "Cameroon" },
  { code: "CA", countryname: "Canada" },
  { code: "CV", countryname: "Cape Verde" },
  { code: "KY", countryname: "Cayman Islands" },
  { code: "CF", countryname: "Central African Republic" },
  { code: "TD", countryname: "Chad" },
  { code: "CL", countryname: "Chile" },
  { code: "CN", countryname: "China" },
  { code: "CX", countryname: "Christmas Island" },
  { code: "CC", countryname: "Cocos (Keeling) Islands" },
  { code: "CO", countryname: "Colombia" },
  { code: "KM", countryname: "Comoros" },
  { code: "CG", countryname: "Congo" },
  { code: "CD", countryname: "Congo, the Democratic Republic of the" },
  { code: "CK", countryname: "Cook Islands" },
  { code: "CR", countryname: "Costa Rica" },
  { code: "CI", countryname: "C\u00f4te d'Ivoire" },
  { code: "HR", countryname: "Croatia" },
  { code: "CU", countryname: "Cuba" },
  { code: "CW", countryname: "Cura\u00e7ao" },
  { code: "CY", countryname: "Cyprus" },
  { code: "CZ", countryname: "Czech Republic" },
  { code: "DK", countryname: "Denmark" },
  { code: "DJ", countryname: "Djibouti" },
  { code: "DM", countryname: "Dominica" },
  { code: "DO", countryname: "Dominican Republic" },
  { code: "EC", countryname: "Ecuador" },
  { code: "EG", countryname: "Egypt" },
  { code: "SV", countryname: "El Salvador" },
  { code: "GQ", countryname: "Equatorial Guinea" },
  { code: "ER", countryname: "Eritrea" },
  { code: "EE", countryname: "Estonia" },
  { code: "ET", countryname: "Ethiopia" },
  { code: "FK", countryname: "Falkland Islands (Malvinas)" },
  { code: "FO", countryname: "Faroe Islands" },
  { code: "FJ", countryname: "Fiji" },
  { code: "FI", countryname: "Finland" },
  { code: "FR", countryname: "France" },
  { code: "GF", countryname: "French Guiana" },
  { code: "PF", countryname: "French Polynesia" },
  { code: "TF", countryname: "French Southern Territories" },
  { code: "GA", countryname: "Gabon" },
  { code: "GM", countryname: "Gambia" },
  { code: "GE", countryname: "Georgia" },
  { code: "DE", countryname: "Germany" },
  { code: "GH", countryname: "Ghana" },
  { code: "GI", countryname: "Gibraltar" },
  { code: "GR", countryname: "Greece" },
  { code: "GL", countryname: "Greenland" },
  { code: "GD", countryname: "Grenada" },
  { code: "GP", countryname: "Guadeloupe" },
  { code: "GU", countryname: "Guam" },
  { code: "GT", countryname: "Guatemala" },
  { code: "GG", countryname: "Guernsey" },
  { code: "GN", countryname: "Guinea" },
  { code: "GW", countryname: "Guinea-Bissau" },
  { code: "GY", countryname: "Guyana" },
  { code: "HT", countryname: "Haiti" },
  { code: "HM", countryname: "Heard Island and McDonald Islands" },
  { code: "VA", countryname: "Holy See (Vatican City State)" },
  { code: "HN", countryname: "Honduras" },
  { code: "HK", countryname: "Hong Kong" },
  { code: "HU", countryname: "Hungary" },
  { code: "IS", countryname: "Iceland" },
  { code: "ID", countryname: "Indonesia" },
  { code: "IR", countryname: "Iran, Islamic Republic of" },
  { code: "IQ", countryname: "Iraq" },
  { code: "IE", countryname: "Ireland" },
  { code: "IM", countryname: "Isle of Man" },
  { code: "IL", countryname: "Israel" },
  { code: "IT", countryname: "Italy" },
  { code: "JM", countryname: "Jamaica" },
  { code: "JP", countryname: "Japan" },
  { code: "JE", countryname: "Jersey" },
  { code: "JO", countryname: "Jordan" },
  { code: "KZ", countryname: "Kazakhstan" },
  { code: "KE", countryname: "Kenya" },
  { code: "KI", countryname: "Kiribati" },
  { code: "KP", countryname: "Korea, Democratic People's Republic of" },
  { code: "KR", countryname: "Korea, Republic of" },
  { code: "KW", countryname: "Kuwait" },
  { code: "KG", countryname: "Kyrgyzstan" },
  { code: "LA", countryname: "Lao People's Democratic Republic" },
  { code: "LV", countryname: "Latvia" },
  { code: "LB", countryname: "Lebanon" },
  { code: "LS", countryname: "Lesotho" },
  { code: "LR", countryname: "Liberia" },
  { code: "LY", countryname: "Libya" },
  { code: "LI", countryname: "Liechtenstein" },
  { code: "LT", countryname: "Lithuania" },
  { code: "LU", countryname: "Luxembourg" },
  { code: "MO", countryname: "Macao" },
  { code: "MK", countryname: "Macedonia, the Former Yugoslav Republic of" },
  { code: "MG", countryname: "Madagascar" },
  { code: "MW", countryname: "Malawi" },
  { code: "MY", countryname: "Malaysia" },
  { code: "MV", countryname: "Maldives" },
  { code: "ML", countryname: "Mali" },
  { code: "MT", countryname: "Malta" },
  { code: "MH", countryname: "Marshall Islands" },
  { code: "MQ", countryname: "Martinique" },
  { code: "MR", countryname: "Mauritania" },
  { code: "MU", countryname: "Mauritius" },
  { code: "YT", countryname: "Mayotte" },
  { code: "MX", countryname: "Mexico" },
  { code: "FM", countryname: "Micronesia, Federated States of" },
  { code: "MD", countryname: "Moldova, Republic of" },
  { code: "MC", countryname: "Monaco" },
  { code: "MN", countryname: "Mongolia" },
  { code: "ME", countryname: "Montenegro" },
  { code: "MS", countryname: "Montserrat" },
  { code: "MA", countryname: "Morocco" },
  { code: "MZ", countryname: "Mozambique" },
  { code: "MM", countryname: "Myanmar" },
  { code: "NA", countryname: "Namibia" },
  { code: "NR", countryname: "Nauru" },
  { code: "NP", countryname: "Nepal" },
  { code: "NL", countryname: "Netherlands" },
  { code: "NC", countryname: "New Caledonia" },
  { code: "NZ", countryname: "New Zealand" },
  { code: "NI", countryname: "Nicaragua" },
  { code: "NE", countryname: "Niger" },
  { code: "NG", countryname: "Nigeria" },
  { code: "NU", countryname: "Niue" },
  { code: "NF", countryname: "Norfolk Island" },
  { code: "MP", countryname: "Northern Mariana Islands" },
  { code: "NO", countryname: "Norway" },
  { code: "OM", countryname: "Oman" },
  { code: "PK", countryname: "Pakistan" },
  { code: "PW", countryname: "Palau" },
  { code: "PS", countryname: "Palestine, State of" },
  { code: "PA", countryname: "Panama" },
  { code: "PG", countryname: "Papua New Guinea" },
  { code: "PY", countryname: "Paraguay" },
  { code: "PE", countryname: "Peru" },
  { code: "PH", countryname: "Philippines" },
  { code: "PN", countryname: "Pitcairn" },
  { code: "PL", countryname: "Poland" },
  { code: "PT", countryname: "Portugal" },
  { code: "PR", countryname: "Puerto Rico" },
  { code: "QA", countryname: "Qatar" },
  { code: "RE", countryname: "R\u00e9union" },
  { code: "RO", countryname: "Romania" },
  { code: "RU", countryname: "Russian Federation" },
  { code: "RW", countryname: "Rwanda" },
  { code: "BL", countryname: "Saint Barth\u00e9lemy" },
  { code: "SH", countryname: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "KN", countryname: "Saint Kitts and Nevis" },
  { code: "LC", countryname: "Saint Lucia" },
  { code: "MF", countryname: "Saint Martin (French part)" },
  { code: "PM", countryname: "Saint Pierre and Miquelon" },
  { code: "VC", countryname: "Saint Vincent and the Grenadines" },
  { code: "WS", countryname: "Samoa" },
  { code: "SM", countryname: "San Marino" },
  { code: "ST", countryname: "Sao Tome and Principe" },
  { code: "SA", countryname: "Saudi Arabia" },
  { code: "SN", countryname: "Senegal" },
  { code: "RS", countryname: "Serbia" },
  { code: "SC", countryname: "Seychelles" },
  { code: "SL", countryname: "Sierra Leone" },
  { code: "SG", countryname: "Singapore" },
  { code: "SX", countryname: "Sint Maarten (Dutch part)" },
  { code: "SK", countryname: "Slovakia" },
  { code: "SI", countryname: "Slovenia" },
  { code: "SB", countryname: "Solomon Islands" },
  { code: "SO", countryname: "Somalia" },
  { code: "ZA", countryname: "South Africa" },
  { code: "GS", countryname: "South Georgia and the South Sandwich Islands" },
  { code: "SS", countryname: "South Sudan" },
  { code: "ES", countryname: "Spain" },
  { code: "LK", countryname: "Sri Lanka" },
  { code: "SD", countryname: "Sudan" },
  { code: "SR", countryname: "Suricountryname" },
  { code: "SJ", countryname: "Svalbard and Jan Mayen" },
  { code: "SZ", countryname: "Swaziland" },
  { code: "SE", countryname: "Sweden" },
  { code: "CH", countryname: "Switzerland" },
  { code: "SY", countryname: "Syrian Arab Republic" },
  { code: "TW", countryname: "Taiwan, Province of China" },
  { code: "TJ", countryname: "Tajikistan" },
  { code: "TZ", countryname: "Tanzania, United Republic of" },
  { code: "TH", countryname: "Thailand" },
  { code: "TL", countryname: "Timor-Leste" },
  { code: "TG", countryname: "Togo" },
  { code: "TK", countryname: "Tokelau" },
  { code: "TO", countryname: "Tonga" },
  { code: "TT", countryname: "Trinidad and Tobago" },
  { code: "TN", countryname: "Tunisia" },
  { code: "TR", countryname: "Turkey" },
  { code: "TM", countryname: "Turkmenistan" },
  { code: "TC", countryname: "Turks and Caicos Islands" },
  { code: "TV", countryname: "Tuvalu" },
  { code: "UG", countryname: "Uganda" },
  { code: "UA", countryname: "Ukraine" },
  { code: "AE", countryname: "United Arab Emirates" },
  { code: "GB", countryname: "United Kingdom" },
  { code: "US", countryname: "United States" },
  { code: "UM", countryname: "United States Minor Outlying Islands" },
  { code: "UY", countryname: "Uruguay" },
  { code: "UZ", countryname: "Uzbekistan" },
  { code: "VU", countryname: "Vanuatu" },
  { code: "VE", countryname: "Venezuela, Bolivarian Republic of" },
  { code: "VN", countryname: "Viet Nam" },
  { code: "VG", countryname: "Virgin Islands, British" },
  { code: "VI", countryname: "Virgin Islands, U.S." },
  { code: "WF", countryname: "Wallis and Futuna" },
  { code: "EH", countryname: "Western Sahara" },
  { code: "YE", countryname: "Yemen" },
  { code: "ZM", countryname: "Zambia" },
  { code: "ZW", countryname: "Zimbabwe" },
];
